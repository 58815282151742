import axios from 'axios'
import { get_prices } from '@/config/endpoints'
import { useQuery, UseQueryOptions } from 'react-query'

export interface IPracticesInfo {
	cod: string,
	currency: string,
	id: string,
	name: string,
	conditions:string,
	plan: string,
	practiceId: string,
	price: string,
	vigence: {
		from: string,
		to: string
	}
}

interface IUsePractices {
	specialtie: string,
	uid: string,
	dependantUid?: string
}

type QueryOptions = Omit<UseQueryOptions<IPracticesInfo[], unknown, IPracticesInfo[], string[]>, 'queryKey' | 'queryFn'>

function usePractices({
	specialtie,
	uid,
	dependantUid
} : IUsePractices, options? : QueryOptions) {

	let path : string

	if (dependantUid){
		path = `${get_prices}/${specialtie}/${uid}/${dependantUid}`
	} else {
		path = `${get_prices}/${specialtie}/${uid}`
	}

	return useQuery(
		['UmaNodeServer-Query','practices', specialtie, uid], 
		async() => {
			const practices = await axios.get<Array<IPracticesInfo>>(path)
			return practices.data
		},
		options
	)
}

export default usePractices