/**
 * 🚨 usar sólo en rutas privadas 🚨 ya que en caso contrario corremos el riesgo de que client sea null
 */

import { IClientState } from '@/OldRouter/Providers/ProviderClient'


export const isIomaClient = (client:IClientState | null):boolean => client?.client === 'IOMA'
export const isIomaUMAClient = (client:IClientState | null):boolean => client?.client === 'IOMA-UMA'
export const isOspreraClient = (client:IClientState | null):boolean => client?.client === 'OSPRERA'
export const isUmaClient = (client:IClientState | null):boolean => client?.client === 'UMA'
export const isPFA = (client:IClientState | null):boolean => client?.client === 'POLICIA FEDERAL'
export const isFarmatodo = (client:IClientState | null):boolean => client?.client === 'FARMATODO'