import axios, { AxiosError, AxiosResponse } from 'axios'
import { getFirebaseIdToken } from '@umahealth/fe-firebase'
import { mp_payment_url_refunds_especialista, mp_payment_url_refunds_guardia } from '@/config/endpoints'
import { errorHandler } from '@/config/ErrorBoundary'
import { useMutation } from 'react-query'
import { alreadyRefundedErrorMsg } from '@/components/OnlineDoctor/Support/utils/errorMsg'
import { refundProblems, refundSucess } from '@/components/OnlineDoctor/Support/Components/RefundButtomSheet'
import { problems } from '@/components/OnlineDoctor/Support/Components/problemsRender'
import { sucessStatus } from '@/components/OnlineDoctor/Support/Components/sucessRender'

export const refundResults = {
	UmaCreditos: 'umaCredits',
	MercadoPago: 'refunded',
}

export type guardia = 'guardia'
export type especialista = 'especialista'

export const refundServices: {
	guardia: guardia,
	especialista: especialista,
} = {
	guardia: 'guardia',
	especialista: 'especialista',
}

type UmaCreditos = 'UmaCreditos'
type MercadoPago = 'MercadoPago'
type Subscription = 'Subscription'

export const refundValueMethods: {
	UmaCreditos: UmaCreditos,
	MercadoPago: MercadoPago,
	Subscription: Subscription,
} = {
	UmaCreditos: 'UmaCreditos',
	MercadoPago: 'MercadoPago',
	Subscription: 'Subscription'
}

export type refundMethodsWithoutSubscription = UmaCreditos | MercadoPago

export interface IRefund {
	uid: string,
	paymentId: string,
	price: number,
	service: guardia | especialista,
	assignationId: string,
}

export interface IRefundProps {
	method: refundMethodsWithoutSubscription,
	setSuccess?: React.Dispatch<React.SetStateAction<false | refundSucess | sucessStatus>>,
	setProblem?: React.Dispatch<React.SetStateAction<false | refundProblems | problems | string>>,
	onError?: ((error: AxiosError, variables: IRefund, context: unknown) => void | Promise<unknown>),
	onSuccess?: ((data: string, variables: IRefund, context: unknown) => void | Promise<unknown>),
}

/**
 * Devuelve el dinero al cliente a través de umacreditos o mercadopago
 * @returns 
 */
async function refund({uid, paymentId, price, service, assignationId} : IRefund, method: refundMethodsWithoutSubscription) : Promise<string> {
	let token : string
	const path = service === 'guardia' 
		? mp_payment_url_refunds_guardia
		: mp_payment_url_refunds_especialista

	try{
		token = await getFirebaseIdToken()
	} catch(err){
		if(errorHandler) errorHandler.report(err as Error)
		throw new Error(`${err}`)
	}

	const headers = { 
		'Authorization': `Bearer ${token}`,
		'uid': uid, 
		'x-api-key': process.env.NEXT_PUBLIC_UMA_BACKEND_APIKEY 
	}

	const refundData = {
		cashback: method === refundValueMethods.MercadoPago,
		country: process.env.NEXT_PUBLIC_COUNTRY,
		paymentId: paymentId,
		service,
		transactionAmount: price,
		uid: uid,
		umaCreditsRefund: method === refundValueMethods.UmaCreditos,
		assignationId,
	}

	const response: AxiosResponse<string> = await axios.post(
		path,
		refundData,
		{ headers }
	)
	return response.data
}

function useRefunds(props: IRefundProps) {
	let predefinedOnError: ((error: AxiosError, variables: IRefund, context: unknown) => void) | undefined = undefined
	let predefinedOnSuccess: ((data: string, variables: IRefund, context: unknown) => void) | undefined = undefined

	if (props.setProblem || (props.setSuccess && props.setProblem)) {
		const { setProblem, setSuccess, method } = props
		predefinedOnError = (error) => {
			if(errorHandler) errorHandler.report(error as Error)
		
			const errorMsg = error.response?.data?.['exception']?.message as string
			if(errorMsg.includes(alreadyRefundedErrorMsg)) {
				setProblem('Turno ya cancelado')
			}
			else setProblem(`Reintegro ${method}`)
		}
		if (setSuccess) {
			predefinedOnSuccess = (data) => {
				if(data === refundResults[method]) {
					setSuccess(`Reintegro efectuado ${method}`)}
				else setProblem(`Reintegro ${method}`)
			}
		}
	}

	return useMutation<string, AxiosError, IRefund>(['Megalith-Query','Refund'], async (data: IRefund) => {
		return refund(data, props.method)
	}, {
		onError: props.onError || predefinedOnError,
		onSuccess: props.onSuccess || predefinedOnSuccess,
	})
}

export default useRefunds
