import { appointmentServices } from '@umahealth/entities'
import { getFirebaseIdToken } from '@umahealth/fe-firebase'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { save_bag_attached, save_chatAtt_attached, save_online_attached } from '@/config/endpoints'
import { useMutation } from 'react-query'

export interface ISaveAttached {
	id: string,
	service: appointmentServices,
	file: Blob
}

interface ISaveAttachedResponse {
	fileUrl: string
}

function useSaveAttached(uid: string) {
	return useMutation<AxiosResponse<ISaveAttachedResponse>, AxiosError, ISaveAttached>(
		['Megalith-Query','useSaveAttached', uid], async (data: ISaveAttached) => {
			const token = await getFirebaseIdToken()

			const headers = {
				'Authorization': `Bearer ${token}`,
				'uid': uid,
				'x-api-key': process.env.NEXT_PUBLIC_UMA_BACKEND_APIKEY
			}
			
			/** Utilizamos formData porque en un body normal no podemos mandar el file */
			const saveAttachedData = new FormData()
			saveAttachedData.append('file', data.file)
			saveAttachedData.append('id', data.id)
			saveAttachedData.append('uid', uid)
			saveAttachedData.append('service', data.service)
			saveAttachedData.append('country', process.env.NEXT_PUBLIC_COUNTRY)
			let saveAttachedEndpoint = save_bag_attached

			if (data.service === 'online') {
				saveAttachedEndpoint = save_online_attached
			}
			if (data.service === 'chatAtt') {
				saveAttachedEndpoint = save_chatAtt_attached
			}

			const saveAttachedResponse = await axios.post<ISaveAttachedResponse>(saveAttachedEndpoint, saveAttachedData, { headers })
			
			return saveAttachedResponse
		})
}

export default useSaveAttached